<template>
  <v-tabs
    class="zg-inner-tab-helper"
    grow
    flat
    hide-slider
    show-arrows
    :value="activeTab"
    @change="onChangeTab"
    next-icon="mdi-chevron-right icons--text"
    prev-icon="mdi-chevron-left icons--text"
  >
    <slot></slot>
  </v-tabs>
</template>

<script>
export default {
  props: {
    value: {},
    customHandler: {},
  },
  computed: {
    activeTab: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    onChangeTab(newTab) {
      if (!this.customHandler) {
        this.activeTab = newTab;
      } else {
        this.customHandler(newTab);
      }
    },
  },
};
</script>

<style scoped lang="css">
.zg-inner-tab-helper {
  border-radius: 8px !important;
}
.zg-inner-tab-helper
  div.v-tabs__bar.theme--light
  div.v-tabs__wrapper.v-tabs__wrapper--show-arrows {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.zg-inner-tab-helper .v-tabs__icon {
  background-color: white;
  z-index: 1 !important;
}
</style>

<style>
.zg-inner-tab-helper > .v-item-group > .v-slide-group__next {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
}

.zg-inner-tab-helper > .v-item-group > .v-slide-group__next .v-icon {
  font-size: 40px !important;
}

.zg-inner-tab-helper > .v-item-group > .v-slide-group__prev {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
}

.zg-inner-tab-helper > .v-item-group > .v-slide-group__prev .v-icon {
  font-size: 40px !important;
}
</style>